@charset "UTF-8";
@import "../node_modules/bootstrap-css-only/css/bootstrap.min.css";
/* stylelint-disable */
@import "assets/styles/c3/c3.min.css";
@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
@import "../node_modules/react-image-lightbox/style.css";
body {
  /*  ANT DESIGN BACKTOP */
  /*  ANT DESIGN BADGE */
  /*  ANT DESIGN CALENDAR */
  /*  ANT DESIGN CASCADER */
  /*  ANT DESIGN DROPDOWN */
  /*  ANT DESIGN FORM */
  /*  ANT DESIGN INPUT */
  /*  ANT DESIGN MODAL */
  /*  ANT DESIGN SELECT */
  /*  ANT DESIGN SLIDER */
  /*  ANT DESIGN SPIN */
  /*  ANT DESIGN LAYOUT */
  /*  ANT DESIGN NOTIFICATION */
  /*  ANT DESIGN PROGRESS */
  /*  ANT DESIGN MENU */
}
body .ant-back-top {
  right: 3.84rem;
  bottom: 3.84rem;
}
body .ant-back-top-content {
  border-radius: 5px;
  background: #d2d9e5;
}
body .ant-back-top-content:hover {
  background: #b8beca;
}
body .ant-badge-count {
  font-family: inherit;
}
body .ant-calendar {
  border: 1px solid #e9e9e9;
}
body .events {
  list-style: none;
  margin: 0;
  padding: 0;
}
body .events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
body .notes-month {
  text-align: center;
  font-size: 28px;
}
body .notes-month section {
  font-size: 28px;
}
body .ant-fullcalendar-month-select {
  margin-left: 5px;
}
body .ant-calendar-picker {
  min-width: auto !important;
}
body .ant-calendar-picker-container {
  animation-duration: 0s !important;
}
body .ant-calendar-range .ant-calendar-input-wrap {
  height: 38px;
}
body .ant-cascader-menus {
  border: 1px solid #e9e9e9;
}
body .ant-dropdown-menu {
  border: 1px solid #e9e9e9;
}
body .ant-dropdown-menu-item {
  font-size: 1rem;
}
body .ant-dropdown-menu-submenu-title {
  font-size: 1rem;
  padding: 0.61rem 1.53rem;
}
body .ant-dropdown-menu-item > a,
body .ant-dropdown-menu-submenu-title > a {
  padding: 0.61rem 1.53rem;
  margin: -0.62rem -1.54rem;
}
body .ant-dropdown-menu-item > a.btn,
body .ant-dropdown-menu-submenu-title > a.btn {
  margin: -0.62rem 0;
}
body .ant-dropdown-menu-item-group-list {
  list-style: none;
  padding: 0;
}
body .ant-dropdown-menu-item-group-title {
  font-weight: bold;
  font-size: 1rem;
  padding: 0.92rem 1.53rem;
  color: #74708d;
}
body .ant-select-dropdown-menu-item-active {
  background-color: #f2f4f8 !important;
}
body .ant-dropdown-menu-item:hover, body .ant-dropdown-menu-submenu-title:hover {
  background-color: #f2f4f8;
}
body .ant-dropdown-menu-item-selected, body .ant-dropdown-menu-submenu-title-selected {
  background-color: #f2f4f8;
}
body .ant-form input[type=file] {
  min-height: 40px;
}
body .ant-form input.ant-input-sm[type=file] {
  min-height: 48px;
}
body .ant-form input.ant-input-lg[type=file] {
  min-height: 52px;
}
body .ant-form-item-label {
  text-align: right;
  white-space: normal;
}
body .ant-form-item > .ant-form-item,
body .ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}
body form .ant-select:only-child,
body form .ant-cascader-picker:only-child {
  display: inline-block;
}
body .ant-input-disabled {
  background: #f9fafc;
}
body .ant-modal-close {
  outline: none !important;
}
body .ant-select {
  font-family: "Nunito Sans", sans-serif;
}
body .ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
  width: auto !important;
}
body .ant-select-auto-complete ol,
body .ant-select-auto-complete ul,
body .ant-select-auto-complete dl {
  margin: 0;
}
body .ant-slider-with-marks {
  margin-bottom: 19px;
}
body .ant-spin-blur {
  filter: none !important;
}
body .ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}
body .style_content__3ONJs {
  height: 100%;
}
body .ant-layout {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  position: relative;
  width: 100vw;
  margin: 0 !important;
}
body .body * {
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: "Nunito Sans";
  /* stylelint-enable font-family-no-missing-generic-family-keyword */
}
body .ant-layout-header {
  padding: 10px 32px;
  width: 100vw;
}
body .ant-layout-footer {
  padding: 0;
  background: transparent;
}
body .ant-collapse {
  background: transparent;
}
body .ant-layout-sider {
  transition: all 0.2s;
  background-color: #fff;
  z-index: 0 !important;
  box-shadow: none !important;
}
body .ant-menu-item-active {
  background-color: #444 !important;
  color: white !important;
}
body .ant-menu-item-selected {
  color: white;
}
@media (max-width: 768px) {
  body .ant-layout-sider {
    z-index: 111 !important;
  }
}
@media (max-width: 768px) {
  body .ant-layout-header {
    padding: 15px;
  }
}
body .ant-notification {
  max-width: calc(100vw - 48px);
}
body .ant-progress-text {
  white-space: nowrap;
}
body .ant-menu-item {
  outline: none !important;
}
body .ant-menu-dark.ant-menu-submenu-popup .ant-menu-item-divider {
  opacity: 0.2;
}
body .donecheck .ant-checkbox {
  transform: scale(1);
  top: 5%;
}
body .donecheck .ant-checkbox .ant-checkbox-inner {
  border-radius: 100%;
  padding: 8px;
  width: 25px;
  height: 25px;
}
body .donecheck .ant-checkbox-checked .ant-checkbox-inner {
  background: green;
  border-color: green;
}
body .donecheck .ant-checkbox-checked .ant-checkbox-inner:after {
  transform-origin: center center;
  transform: rotate(45deg) scale(1.2) translate(-20%, -55%);
}
body .ant-checkbox-wrapper-disabled .ant-checkbox {
  transform: scale(1);
  top: 5%;
}
body .ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner {
  border-radius: 100%;
  padding: 8px;
  width: 25px;
  height: 25px;
}
body .ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
  background: #f2f4f8;
  border-color: gray;
}
body .ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner:after {
  transform-origin: center center;
  transform: rotate(45deg) scale(1.2) translate(-20%, -55%);
}

/*  CORE MODULE STYLES */
html {
  font-size: 13px;
  overflow-y: scroll;
}
@media (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

body {
  font-family: "Nunito Sans", sans-serif !important;
  color: #514d6a;
  background: #f2f4f8;
  line-height: 1.7;
}
@media (max-width: 767px) {
  body {
    font-size: 12px;
  }
}

svg {
  vertical-align: baseline;
  overflow: auto;
}

a {
  text-decoration: none;
  color: #74708d;
  transition: color 0s ease-in-out;
}
a:hover, a:active, a:focus {
  color: #08f;
  text-decoration: none;
}

input {
  outline: none !important;
  font-family: "Nunito Sans", sans-serif !important;
  color: #514d6a;
}

button,
input {
  box-shadow: none !important;
}

input[type=text],
input[type=password],
input[type=email],
textarea {
  appearance: none !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

code {
  color: #74708d;
  background: #f2f4f8;
  display: inline-block;
  padding: 0.15rem 0.38rem;
  border-radius: 3px;
}

pre {
  color: #74708d;
  padding: 1.53rem;
  border-radius: 3px;
  border: 1px solid #e4e9f0;
  background: #f2f4f8;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

/*  CORE COMPONENTS & UTILITIES */
.utils__visibilityHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}
.utils__content {
  padding: 2.3rem;
  max-width: 120rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .utils__content {
    padding: 2.3rem 0.76rem !important;
  }
}
.utils__title {
  font-size: 1.3rem;
  color: #0e0b20;
}
.utils__titleDescription {
  color: #c0bdd0;
}
.utils__scrollable {
  overflow: auto;
}
.utils__example {
  padding: 1.92rem;
  border: 1px solid #e4e9f0;
}
.utils__example:before {
  content: "EXAMPLE";
  display: block;
  margin-bottom: 1.15rem;
  color: #74708d;
}
.utils__link--underlined {
  border-bottom: 1px solid rgba(57, 55, 73, 0.25);
}
.utils__link--underlined:hover {
  border-bottom-color: rgba(0, 136, 255, 0.2);
}
.utils__link--blue {
  color: #08f;
}
.utils__link--blue:hover {
  color: #0072d6;
}
.utils__link--blue.link-underlined {
  border-bottom: 1px solid rgba(0, 136, 255, 0.25);
}
.utils__link--blue.link-underlined:hover {
  border-bottom-color: rgba(0, 136, 255, 0.2);
}
.utils__iconsBlock {
  padding: 0 0 2.3rem;
  text-align: center;
}
.utils__iconsBlock p {
  height: 2.3rem;
}
.utils__iconsBlock li {
  text-align: center;
  display: inline-block;
  width: 3.07rem;
  height: 3.07rem;
  line-height: 3.07rem;
  font-size: 2.76rem;
  padding: 0.76rem 2.3rem;
  box-sizing: content-box;
  border-radius: 3px;
  background: #eef0f4;
  margin: 0.76rem;
}
.utils__iconsBlock li:hover {
  background: #222034;
  color: #fff;
}
.utils__iconsBlock .tooltip {
  font-size: 1.38rem;
}
.utils__scrollTable .ant-table-body {
  overflow-x: hidden !important;
}

/*  DEFAULT MEASUREMENTS STYLES */
.font-size-0 {
  font-size: 0rem;
}

.font-size-10 {
  font-size: 0.76rem;
}

.font-size-12 {
  font-size: 0.92rem;
}

.font-size-14 {
  font-size: 1.07rem;
}

.font-size-16 {
  font-size: 1.23rem;
}

.font-size-18 {
  font-size: 1.38rem;
}

.font-size-20 {
  font-size: 1.53rem;
}

.font-size-24 {
  font-size: 1.84rem;
}

.font-size-26 {
  font-size: 2rem;
}

.font-size-30 {
  font-size: 2.3rem;
}

.font-size-36 {
  font-size: 2.76rem;
}

.font-size-40 {
  font-size: 3.07rem;
}

.font-size-50 {
  font-size: 3.84rem;
}

.font-size-60 {
  font-size: 4.61rem;
}

.font-size-70 {
  font-size: 5.38rem;
}

.font-size-80 {
  font-size: 6.15rem;
}

.height-100 {
  height: 7.69rem;
}

.height-200 {
  height: 15.38rem;
}

.height-300 {
  height: 23.07rem;
}

.height-400 {
  height: 30.76rem;
}

.height-500 {
  height: 38.46rem;
}

.height-600 {
  height: 46.15rem;
}

.height-700 {
  height: 53.84rem;
}

.min-height-100 {
  min-height: 7.69rem;
}

.min-height-200 {
  min-height: 15.38rem;
}

.min-height-300 {
  min-height: 23.07rem;
}

.min-height-400 {
  min-height: 30.76rem;
}

.min-height-500 {
  min-height: 38.46rem;
}

.min-height-600 {
  min-height: 46.15rem;
}

.min-height-700 {
  min-height: 53.84rem;
}

.width-50 {
  width: 3.84rem;
}

.width-75 {
  width: 5.76rem;
}

.width-100 {
  width: 7.69rem;
}

.width-150 {
  width: 11.53rem;
}

.width-200 {
  width: 15.38rem;
}

.width-300 {
  width: 23.07rem;
}

.width-400 {
  width: 30.76rem;
}

.width-500 {
  width: 38.46rem;
}

.width-600 {
  width: 46.15rem;
}

.width-700 {
  width: 53.84rem;
}

.width-30p {
  width: 30%;
}

.width-50p {
  width: 50%;
}

.width-60p {
  width: 60%;
}

.width-100p {
  width: 100%;
}

.max-width-50 {
  max-width: 3.84rem;
}

.max-width-75 {
  max-width: 5.76rem;
}

.max-width-100 {
  max-width: 7.69rem;
}

.max-width-200 {
  max-width: 15.38rem;
}

.max-width-300 {
  max-width: 23.07rem;
}

.max-width-400 {
  max-width: 30.76rem;
}

.max-width-500 {
  max-width: 38.46rem;
}

.max-width-600 {
  max-width: 46.15rem;
}

.max-width-700 {
  max-width: 53.84rem;
}

.max-width-30p {
  max-width: 30%;
}

.max-width-50p {
  max-width: 50%;
}

.max-width-60p {
  max-width: 60%;
}

.max-width-100p {
  max-width: 100% !important;
}

/*  THEME SETTINGS STYLES */
.settings__fixedWidth {
  max-width: 1420px;
  margin: 0 auto;
}
.settings__fixedWidth:not(.settings__menuTop) .utils__content {
  padding-right: 0;
}
.settings__fixedWidth:not(.settings__menuTop) div[class^=style-module__inner] {
  margin-right: 0;
}
@media (max-width: 1445px) {
  .settings__fixedWidth:not(.settings__menuTop) .utils__content {
    padding-right: 2.3rem;
  }
  .settings__fixedWidth:not(.settings__menuTop) div[class^=style-module__inner] {
    margin-right: 2.3rem;
  }
}
.settings__fixedWidth.settings__menuTop .utils__content {
  padding-left: 0;
  padding-right: 0;
}
.settings__fixedWidth.settings__menuTop div[class^=style-module__inner] {
  margin: 0;
}
@media (max-width: 1445px) {
  .settings__fixedWidth.settings__menuTop .utils__content {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
  }
  .settings__fixedWidth.settings__menuTop div[class^=style-module__inner] {
    margin: 0 2.3rem;
  }
}
.settings__squaredBorders .card {
  border-radius: 0;
}
.settings__menuShadow .ant-layout-sider {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 200px -20px rgba(57, 55, 73, 0.4);
}
.settings__borderLess .card {
  border: none;
}
.settings__borderLess .card-header {
  padding-top: 1.53rem;
  padding-bottom: 0;
  border-bottom: 0;
}
.settings__borderLess .card-footer {
  padding-top: 0;
  border-top: 0;
}

/* GRID */
.row {
  margin-left: -1.16rem;
  margin-right: -1.16rem;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}

.container-fluid {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}

@media (max-width: 1599px) {
  .hidden-xxl-down {
    display: none;
  }
}
/* CARDS */
.card,
.card-header,
.list-group-item,
.card-footer {
  border-color: #e4e9f0;
}

.card-header,
.card-footer {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  background: #fff;
}
.card-header:last-child,
.card-footer:last-child {
  border-radius: 0 0 6px 6px;
}
.card-header:first-child,
.card-footer:first-child {
  border-radius: 6px 6px 0 0;
}
@media (max-width: 991px) {
  .card-header,
  .card-footer {
    padding-left: 1.53rem;
    padding-right: 1.53rem;
  }
}

.card-body {
  flex: 1 1 auto;
  padding: 1.92rem 2.3rem;
}
@media (max-width: 991px) {
  .card-body {
    padding-left: 1.53rem;
    padding-right: 1.53rem;
  }
}

.card {
  margin-bottom: 2.3rem;
  border-radius: 7px;
}
.card--withShadow {
  transition: box-shadow 0.1s ease-in-out;
}
.card--withShadow:hover {
  box-shadow: 0 0 2.25rem #e1e1e1;
}
.card--fullHeight {
  height: calc(100% - 2.3rem);
}
.utils__content > .card:last-child {
  margin-bottom: 0;
}

.card--example {
  border: 1px solid #e4e9f0 !important;
}
.card--example .card-header > h5 {
  margin-bottom: 0;
}

/* UTILITIES */
.text-muted {
  color: #c0bdd0 !important;
}
.text-primary {
  color: #0190fe;
}
.text-success {
  color: #46be8a;
}
.text-info {
  color: #0887c9;
}
.text-warning {
  color: #f39834;
}
.text-danger {
  color: #fb434a;
}
.text-default {
  color: #acb7bf;
}
.text-black {
  color: #0e0b20;
}

.bg-primary {
  background-color: #0190fe !important;
}
.bg-success {
  background-color: #46be8a !important;
}
.bg-info {
  background-color: #0887c9 !important;
}
.bg-warning {
  background-color: #f39834 !important;
}
.bg-danger {
  background-color: #fb434a !important;
}
.bg-inverse {
  background-color: #222034 !important;
}
.bg-faded {
  background-color: #f2f4f8 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-default {
  background-color: #acb7bf !important;
}

.progress {
  background: #f2f4f8;
}
.progress-bar {
  height: 1.38rem;
}

/* BUTTONS */
.btn-link {
  color: #74708d;
}
.btn-link:focus, .btn-link:hover {
  color: #615d7c;
  text-decoration: none;
}

.btn {
  outline: none !important;
  box-shadow: none !important;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
  padding: 0.61rem 1.23rem;
  line-height: 1.7;
}
.btn:hover, .btn:active {
  transition: color 0.1s ease-in-out, background 0.1s ease-in-out, border 0.1s ease-in-out;
}
.btn.btn-rounded {
  border-radius: 100px;
}
.btn.btn-squared {
  border-radius: 0;
}
.btn.btn-sm {
  padding: 0.3rem 0.84rem;
}
.btn.btn-lg {
  padding: 0.92rem 1.53rem;
}
.btn.btn-link {
  color: #74708d;
  border-color: transparent !important;
  background: none !important;
  text-decoration: none;
}
.btn.btn-link:hover, .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link.active {
  color: #08f;
}
.btn.btn-icon {
  padding: 0.76rem;
  line-height: 1rem;
  text-decoration: none !important;
}
.btn.btn-icon.btn-sm {
  padding: 0.53rem;
}
.btn.btn-icon.btn-lg {
  padding: 1.53rem;
}

.btn-group-vertical > label {
  margin-bottom: 0;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}
.btn-group-justified > .btn-group > .btn {
  width: 100%;
}
.btn-group-justified.btn-group-vertical {
  display: block;
}
.btn-group-justified.btn-group-vertical > .btn-group {
  display: block;
  width: 100%;
}

.btn,
.show > .btn {
  color: #74708d;
  background-color: #fff;
  border-color: #d2d9e5;
}
.btn:hover, .btn:active,
.show > .btn:hover,
.show > .btn:active {
  background-color: rgb(219.0419354839, 222.0806451613, 228.1580645161);
  border-color: rgb(219.0419354839, 222.0806451613, 228.1580645161);
}
.btn:hover:active, .btn:focus, .btn.active,
.show > .btn:hover:active,
.show > .btn:focus,
.show > .btn.active {
  background: rgb(207.3612903226, 211.3870967742, 219.4387096774);
  border-color: rgb(207.3612903226, 211.3870967742, 219.4387096774);
}
.btn.btn-default, .btn.btn-primary, .btn.btn-secondary, .btn.btn-success, .btn.btn-info, .btn.btn-warning, .btn.btn-danger,
.show > .btn.btn-default,
.show > .btn.btn-primary,
.show > .btn.btn-secondary,
.show > .btn.btn-success,
.show > .btn.btn-info,
.show > .btn.btn-warning,
.show > .btn.btn-danger {
  color: #fff !important;
}
.btn.btn-default,
.show > .btn.btn-default {
  background-color: #acb7bf;
  border-color: #acb7bf;
}
.btn.btn-default:hover, .btn.btn-default:active,
.show > .btn.btn-default:hover,
.show > .btn.btn-default:active {
  background-color: rgb(189.2775510204, 197.987755102, 204.3224489796);
  border-color: rgb(189.2775510204, 197.987755102, 204.3224489796);
}
.btn.btn-default:hover:active, .btn.btn-default:focus, .btn.btn-default.active,
.show > .btn.btn-default:hover:active,
.show > .btn.btn-default:focus,
.show > .btn.btn-default.active {
  background: rgb(154.7224489796, 168.012244898, 177.6775510204);
  border-color: rgb(154.7224489796, 168.012244898, 177.6775510204);
}
.btn.btn-primary,
.show > .btn.btn-primary {
  background-color: #0190fe;
  border-color: #0190fe;
}
.btn.btn-primary:hover, .btn.btn-primary:active,
.show > .btn.btn-primary:hover,
.show > .btn.btn-primary:active {
  background-color: rgb(31.48, 157.32, 254.12);
  border-color: rgb(31.48, 157.32, 254.12);
}
.btn.btn-primary:hover:active, .btn.btn-primary:focus, .btn.btn-primary.active,
.show > .btn.btn-primary:hover:active,
.show > .btn.btn-primary:focus,
.show > .btn.btn-primary.active {
  background: rgb(0.88, 126.72, 223.52) !important;
  border-color: rgb(0.88, 126.72, 223.52) !important;
}
.btn.btn-secondary,
.show > .btn.btn-secondary {
  background-color: #6a7a84;
  border-color: #6a7a84;
}
.btn.btn-secondary:hover, .btn.btn-secondary:active,
.show > .btn.btn-secondary:hover,
.show > .btn.btn-secondary:active {
  background-color: rgb(121.1142857143, 137.3428571429, 147.4857142857);
  border-color: rgb(121.1142857143, 137.3428571429, 147.4857142857);
}
.btn.btn-secondary:hover:active, .btn.btn-secondary:focus, .btn.btn-secondary.active,
.show > .btn.btn-secondary:hover:active,
.show > .btn.btn-secondary:focus,
.show > .btn.btn-secondary.active {
  background: rgb(92.3714285714, 106.3142857143, 115.0285714286);
  border-color: rgb(92.3714285714, 106.3142857143, 115.0285714286);
}
.btn.btn-success,
.show > .btn.btn-success {
  background-color: #46be8a;
  border-color: #46be8a;
}
.btn.btn-success:hover, .btn.btn-success:active,
.show > .btn.btn-success:hover,
.show > .btn.btn-success:active {
  background-color: rgb(92.644, 197.956, 152.3208);
  border-color: rgb(92.644, 197.956, 152.3208);
}
.btn.btn-success:hover:active, .btn.btn-success:focus, .btn.btn-success.active,
.show > .btn.btn-success:hover:active,
.show > .btn.btn-success:focus,
.show > .btn.btn-success.active {
  background: rgb(59.644, 169.756, 122.0408);
  border-color: rgb(59.644, 169.756, 122.0408);
}
.btn.btn-info,
.show > .btn.btn-info {
  background-color: #0887c9;
  border-color: #0887c9;
}
.btn.btn-info:hover, .btn.btn-info:active,
.show > .btn.btn-info:hover,
.show > .btn.btn-info:active {
  background-color: rgb(9.171291866, 154.7655502392, 230.428708134);
  border-color: rgb(9.171291866, 154.7655502392, 230.428708134);
}
.btn.btn-info:hover:active, .btn.btn-info:focus, .btn.btn-info.active,
.show > .btn.btn-info:hover:active,
.show > .btn.btn-info:focus,
.show > .btn.btn-info.active {
  background: rgb(6.828708134, 115.2344497608, 171.571291866);
  border-color: rgb(6.828708134, 115.2344497608, 171.571291866);
}
.btn.btn-warning,
.show > .btn.btn-warning {
  background-color: #f39834;
  border-color: #f39834;
}
.btn.btn-warning:hover, .btn.btn-warning:active,
.show > .btn.btn-warning:hover,
.show > .btn.btn-warning:active {
  background-color: rgb(244.7079069767, 166.6595348837, 80.8920930233);
  border-color: rgb(244.7079069767, 166.6595348837, 80.8920930233);
}
.btn.btn-warning:hover:active, .btn.btn-warning:focus, .btn.btn-warning.active,
.show > .btn.btn-warning:hover:active,
.show > .btn.btn-warning:focus,
.show > .btn.btn-warning.active {
  background: rgb(241.2920930233, 137.3404651163, 23.1079069767);
  border-color: rgb(241.2920930233, 137.3404651163, 23.1079069767);
}
.btn.btn-danger,
.show > .btn.btn-danger {
  background-color: #fb434a;
  border-color: #fb434a;
}
.btn.btn-danger:hover, .btn.btn-danger:active,
.show > .btn.btn-danger:hover,
.show > .btn.btn-danger:active {
  background-color: rgb(251.6375, 96.9625, 102.846875);
  border-color: rgb(251.6375, 96.9625, 102.846875);
}
.btn.btn-danger:hover:active, .btn.btn-danger:focus, .btn.btn-danger.active,
.show > .btn.btn-danger:hover:active,
.show > .btn.btn-danger:focus,
.show > .btn.btn-danger.active {
  background: rgb(250.3625, 37.0375, 45.153125);
  border-color: rgb(250.3625, 37.0375, 45.153125);
}
.btn.btn-outline-default, .btn.btn-outline-primary, .btn.btn-outline-secondary, .btn.btn-outline-success, .btn.btn-outline-info, .btn.btn-outline-warning, .btn.btn-outline-danger,
.show > .btn.btn-outline-default,
.show > .btn.btn-outline-primary,
.show > .btn.btn-outline-secondary,
.show > .btn.btn-outline-success,
.show > .btn.btn-outline-info,
.show > .btn.btn-outline-warning,
.show > .btn.btn-outline-danger {
  background-color: #fff;
}
.btn.btn-outline-default,
.show > .btn.btn-outline-default {
  border-color: #acb7bf;
  color: #acb7bf;
}
.btn.btn-outline-default:hover, .btn.btn-outline-default:active,
.show > .btn.btn-outline-default:hover,
.show > .btn.btn-outline-default:active {
  background-color: rgb(189.2775510204, 197.987755102, 204.3224489796);
  border-color: rgb(189.2775510204, 197.987755102, 204.3224489796);
  color: #fff;
}
.btn.btn-outline-default:hover:active, .btn.btn-outline-default:focus, .btn.btn-outline-default.active,
.show > .btn.btn-outline-default:hover:active,
.show > .btn.btn-outline-default:focus,
.show > .btn.btn-outline-default.active {
  background: rgb(154.7224489796, 168.012244898, 177.6775510204);
  border-color: rgb(154.7224489796, 168.012244898, 177.6775510204);
  color: #fff;
}
.btn.btn-outline-primary,
.show > .btn.btn-outline-primary {
  border-color: #0190fe;
  color: #0190fe;
}
.btn.btn-outline-primary:hover, .btn.btn-outline-primary:active,
.show > .btn.btn-outline-primary:hover,
.show > .btn.btn-outline-primary:active {
  background-color: rgb(31.48, 157.32, 254.12);
  border-color: rgb(31.48, 157.32, 254.12);
  color: #fff;
}
.btn.btn-outline-primary:hover:active, .btn.btn-outline-primary:focus, .btn.btn-outline-primary.active,
.show > .btn.btn-outline-primary:hover:active,
.show > .btn.btn-outline-primary:focus,
.show > .btn.btn-outline-primary.active {
  background: rgb(0.88, 126.72, 223.52);
  border-color: rgb(0.88, 126.72, 223.52);
  color: #fff;
}
.btn.btn-outline-secondary,
.show > .btn.btn-outline-secondary {
  border-color: #6a7a84;
  color: #6a7a84;
}
.btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:active,
.show > .btn.btn-outline-secondary:hover,
.show > .btn.btn-outline-secondary:active {
  background-color: rgb(121.1142857143, 137.3428571429, 147.4857142857);
  border-color: rgb(121.1142857143, 137.3428571429, 147.4857142857);
  color: #fff;
}
.btn.btn-outline-secondary:hover:active, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.active,
.show > .btn.btn-outline-secondary:hover:active,
.show > .btn.btn-outline-secondary:focus,
.show > .btn.btn-outline-secondary.active {
  background: rgb(92.3714285714, 106.3142857143, 115.0285714286);
  border-color: rgb(92.3714285714, 106.3142857143, 115.0285714286);
  color: #fff;
}
.btn.btn-outline-success,
.show > .btn.btn-outline-success {
  border-color: #46be8a;
  color: #46be8a;
}
.btn.btn-outline-success:hover, .btn.btn-outline-success:active,
.show > .btn.btn-outline-success:hover,
.show > .btn.btn-outline-success:active {
  background-color: rgb(92.644, 197.956, 152.3208);
  border-color: rgb(92.644, 197.956, 152.3208);
  color: #fff;
}
.btn.btn-outline-success:hover:active, .btn.btn-outline-success:focus, .btn.btn-outline-success.active,
.show > .btn.btn-outline-success:hover:active,
.show > .btn.btn-outline-success:focus,
.show > .btn.btn-outline-success.active {
  background: rgb(59.644, 169.756, 122.0408);
  border-color: rgb(59.644, 169.756, 122.0408);
  color: #fff;
}
.btn.btn-outline-info,
.show > .btn.btn-outline-info {
  border-color: #0887c9;
  color: #0887c9;
}
.btn.btn-outline-info:hover, .btn.btn-outline-info:active,
.show > .btn.btn-outline-info:hover,
.show > .btn.btn-outline-info:active {
  background-color: rgb(9.171291866, 154.7655502392, 230.428708134);
  border-color: rgb(9.171291866, 154.7655502392, 230.428708134);
  color: #fff;
}
.btn.btn-outline-info:hover:active, .btn.btn-outline-info:focus, .btn.btn-outline-info.active,
.show > .btn.btn-outline-info:hover:active,
.show > .btn.btn-outline-info:focus,
.show > .btn.btn-outline-info.active {
  background: rgb(6.828708134, 115.2344497608, 171.571291866);
  border-color: rgb(6.828708134, 115.2344497608, 171.571291866);
  color: #fff;
}
.btn.btn-outline-warning,
.show > .btn.btn-outline-warning {
  border-color: #f39834;
  color: #f39834;
}
.btn.btn-outline-warning:hover, .btn.btn-outline-warning:active,
.show > .btn.btn-outline-warning:hover,
.show > .btn.btn-outline-warning:active {
  background-color: rgb(244.7079069767, 166.6595348837, 80.8920930233);
  border-color: rgb(244.7079069767, 166.6595348837, 80.8920930233);
  color: #fff;
}
.btn.btn-outline-warning:hover:active, .btn.btn-outline-warning:focus, .btn.btn-outline-warning.active,
.show > .btn.btn-outline-warning:hover:active,
.show > .btn.btn-outline-warning:focus,
.show > .btn.btn-outline-warning.active {
  background: rgb(241.2920930233, 137.3404651163, 23.1079069767);
  border-color: rgb(241.2920930233, 137.3404651163, 23.1079069767);
  color: #fff;
}
.btn.btn-outline-danger,
.show > .btn.btn-outline-danger {
  border-color: #fb434a;
  color: #fb434a;
}
.btn.btn-outline-danger:hover, .btn.btn-outline-danger:active,
.show > .btn.btn-outline-danger:hover,
.show > .btn.btn-outline-danger:active {
  background-color: rgb(251.6375, 96.9625, 102.846875);
  border-color: rgb(251.6375, 96.9625, 102.846875);
  color: #fff;
}
.btn.btn-outline-danger:hover:active, .btn.btn-outline-danger:focus, .btn.btn-outline-danger.active,
.show > .btn.btn-outline-danger:hover:active,
.show > .btn.btn-outline-danger:focus,
.show > .btn.btn-outline-danger.active {
  background: rgb(250.3625, 37.0375, 45.153125);
  border-color: rgb(250.3625, 37.0375, 45.153125);
  color: #fff;
}

/* TABLE */
.table {
  width: 100% !important;
}
.table thead th {
  border-bottom: 1px solid #e4e9f0;
  outline: none !important;
}
.table thead th:focus {
  background: #e4e9f0;
}
.table td,
.table th {
  border-color: #e4e9f0;
}
.table td:focus,
.table th:focus {
  background-color: #e4e9f0;
}
.table tbody tr:first-child td {
  border-top: none;
}
.table.table-hover tbody tr:hover {
  background: #f2f4f8;
}
.table tr.active,
.table th.active {
  background: #f2f4f8;
}
.table .thead-inverse th {
  background: #222034;
}
.table .thead-default th {
  background: #f2f4f8;
}
.table.table-striped tbody tr:nth-of-type(odd) {
  background: #f2f4f8;
}
.table.table-vertical-middle td,
.table.table-vertical-middle th {
  vertical-align: middle;
}

.table-inverse {
  color: #b8beca;
}
.table-inverse thead th {
  border-bottom-color: #393749;
  color: #fff;
}
.table-inverse th,
.table-inverse td {
  border-top-color: #393749;
}
.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  background: #222034;
}
.table-inverse th:focus,
.table-inverse td:focus,
.table-inverse thead th:focus {
  background: #222034;
}
.table-inverse tr.active,
.table-inverse th.active {
  background: #eef0f4;
}

/* TYPOGRAPHY */
.mark,
mark {
  background: #f2a654;
  color: #fff;
}

.blockquote {
  background: #f2f4f8;
  padding: 30px;
  border-left: 3px solid #d2d9e5;
  font-size: 1.15rem;
}
.blockquote-reverse {
  text-align: right;
  border-left: none;
  border-right: 3px solid #d2d9e5;
}
.blockquote-footer {
  margin-top: 1.53rem;
  color: #b8beca;
}

/* BREADCRUMB */
.breadcrumb {
  background: #f2f4f8;
}
.breadcrumb--custom {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  background: none;
}
.breadcrumb--custom li {
  padding: 0;
}
.breadcrumb--custom li:before {
  display: none !important;
}
.breadcrumb--custom li > a,
.breadcrumb--custom li > span {
  display: inline-block;
  padding: 9px 30px;
  position: relative;
  background: #eef0f4;
  margin: 0 0.61rem 0.46rem;
  font-size: 13px;
}
.breadcrumb--custom li > a:before,
.breadcrumb--custom li > span:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39px 10px 0 0;
  border-color: transparent #eef0f4 transparent transparent;
  transition: all 0s ease-in-out;
}
.breadcrumb--custom li > a:after,
.breadcrumb--custom li > span:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 39px 10px;
  border-color: transparent transparent transparent #eef0f4;
  transition: all 0s ease-in-out;
}
.breadcrumb--custom li > a {
  transition: all 0s ease-in-out;
}
.breadcrumb--custom li > a:hover, .breadcrumb--custom li > a.active {
  background: #08f;
  color: #fff;
}
.breadcrumb--custom li > a:hover:before, .breadcrumb--custom li > a.active:before {
  border-color: transparent #08f transparent transparent;
}
.breadcrumb--custom li > a:hover:after, .breadcrumb--custom li > a.active:after {
  border-color: transparent transparent transparent #08f;
}
.breadcrumb--custom li:first-child > a,
.breadcrumb--custom li:first-child > span {
  margin-left: 0;
}
.breadcrumb--custom li:first-child > a:before,
.breadcrumb--custom li:first-child > span:before {
  display: none;
}
.breadcrumb--custom li:last-child > a:after,
.breadcrumb--custom li:last-child > span:after {
  display: none;
}
.breadcrumb--custom li:after {
  padding: 0;
  content: "";
}

/* FORMS */
input::-webkit-input-placeholder {
  color: #b8beca !important;
}

input::-moz-placeholder {
  color: #b8beca !important;
}

input:-moz-placeholder {
  color: #b8beca !important;
}

input:-ms-input-placeholder {
  color: #b8beca !important;
}

.form-actions {
  border-top: 1px solid #e4e9f0;
  padding-top: 1.53rem;
  margin: 1.53rem 0;
}

.form-control {
  font-family: "Nunito Sans", sans-serif !important;
  padding: 0.84rem 1.23rem;
  border-color: #e4e9f0;
  color: #74708d;
}
.form-control.form-control-rounded {
  border-radius: 500px;
}
.form-control:focus {
  border-color: #0190fe;
}
.form-control.form-control-sm {
  padding: 0.53rem 0.61rem;
}

.col-form-label {
  padding-top: 0.69rem;
  padding-bottom: 0.69rem;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eef0f4;
}

.input-group-addon {
  border-color: #e4e9f0;
  background-color: #eef0f4;
}

span.input-group-addon {
  outline: none !important;
}

.form-group.has-danger .form-control {
  border-color: #fb434a;
}
.form-group.has-success .form-control {
  border-color: #46be8a;
}
.form-group.has-warning .form-control {
  border-color: #f39834;
}
.form-group.has-focused .form-control {
  border-color: #0190fe;
}

.form-input-icon {
  position: relative;
}
.form-input-icon > i {
  color: #b8beca;
  position: absolute;
  margin: 1rem 0.15rem 0.3rem 0.76rem;
  z-index: 3;
  width: 1.23rem;
  font-size: 1.23rem;
  text-align: center;
  left: 0;
}
.form-input-icon .form-control {
  padding-left: 2.61rem;
}
.form-input-icon.form-input-icon-right > i {
  left: auto;
  right: 0.23rem;
  margin: 1rem 0.76rem 0.3rem 0.15rem;
}
.form-input-icon.form-input-icon-right .form-control {
  padding-left: 1.23rem;
  padding-right: 2.61rem;
}

/* DROPDOWNS */
.dropdown-menu {
  border-color: #d2d9e5;
  top: 115%;
  padding: 0.61rem 0;
}
.dropdown-menu .dropdown-icon {
  margin-right: 0.38rem;
}
.dropdown-menu .dropdown-divider {
  background-color: #e4e9f0;
}
.dropdown-menu .dropdown-header {
  padding: 0.53rem 1.15rem;
  color: #615d7c;
  font-size: 1rem;
  font-weight: 600;
}
.dropdown-menu .dropdown-item {
  color: #74708d;
  background: none;
  padding: 0.23rem 1.15rem;
  transition: color 0s ease-in-out;
}
.dropdown-menu .dropdown-item a {
  color: #74708d;
}
.dropdown-menu .dropdown-item a:hover, .dropdown-menu .dropdown-item a:active {
  background: none;
  color: #08f;
}
.dropdown-menu .dropdown-item.disabled {
  cursor: not-allowed;
  color: #e4e9f0 !important;
}
.dropdown-menu .dropdown-item.disabled a {
  color: #e4e9f0 !important;
  cursor: not-allowed;
}
.dropdown-menu .dropdown-item.active {
  background: #eef0f4 !important;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item:focus {
  background: none;
  color: #08f;
}

.dropdown-animate {
  overflow: hidden;
}
.dropdown-animate > .dropdown-item,
.dropdown-animate .dropdown-header {
  animation-name: dropdown-slide-left;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.dropdown-animate > .dropdown-item:nth-child(1),
.dropdown-animate .dropdown-header:nth-child(1) {
  animation-delay: 0.02s;
}
.dropdown-animate > .dropdown-item:nth-child(2),
.dropdown-animate .dropdown-header:nth-child(2) {
  animation-delay: 0.04s;
}
.dropdown-animate > .dropdown-item:nth-child(3),
.dropdown-animate .dropdown-header:nth-child(3) {
  animation-delay: 0.06s;
}
.dropdown-animate > .dropdown-item:nth-child(4),
.dropdown-animate .dropdown-header:nth-child(4) {
  animation-delay: 0.08s;
}
.dropdown-animate > .dropdown-item:nth-child(5),
.dropdown-animate .dropdown-header:nth-child(5) {
  animation-delay: 0.1s;
}
.dropdown-animate > .dropdown-item:nth-child(6),
.dropdown-animate .dropdown-header:nth-child(6) {
  animation-delay: 0.12s;
}
.dropdown-animate > .dropdown-item:nth-child(7),
.dropdown-animate .dropdown-header:nth-child(7) {
  animation-delay: 0.14s;
}
.dropdown-animate > .dropdown-item:nth-child(8),
.dropdown-animate .dropdown-header:nth-child(8) {
  animation-delay: 0.16s;
}
.dropdown-animate > .dropdown-item:nth-child(9),
.dropdown-animate .dropdown-header:nth-child(9) {
  animation-delay: 0.18s;
}
.dropdown-animate > .dropdown-item:nth-child(10),
.dropdown-animate .dropdown-header:nth-child(10) {
  animation-delay: 0.2s;
}

.show > .dropdown-menu,
.dropdown-menu.open > .dropdown-menu {
  animation-name: dropdown-slide-bottom;
  animation-duration: 0.15s;
  animation-fill-mode: both;
  animation-delay: 0.05s;
  display: block;
}
.show.dropup > .dropdown-menu,
.dropdown-menu.open.dropup > .dropdown-menu {
  animation-name: dropdown-slide-top;
}

.dropdown .dropdown-toggle {
  position: relative;
}
.dropdown .dropdown-toggle:after {
  margin-top: -0.08rem;
  color: #d2d9e5;
  transition: color 0s ease-in-out;
}
.dropdown .dropdown-toggle > i {
  color: #d2d9e5;
  transition: color 0s ease-in-out;
}
.dropdown:hover .dropdown-toggle > i, .dropdown.show .dropdown-toggle > i {
  color: #b8beca;
}
.dropdown:hover .dropdown-toggle:after, .dropdown.show .dropdown-toggle:after {
  color: #b8beca;
}

@keyframes dropdown-slide-left {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes dropdown-slide-bottom {
  0% {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes dropdown-slide-top {
  0% {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/* SELECTBOXES */
select {
  appearance: none;
}

select.form-control {
  color: #74708d;
  height: auto;
  background: #fff center right no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFCAYAAABB9hwOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNWZkMzNlMC0zNTcxLTI4NDgtYjA3NC01ZTRhN2RjMWVmNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODZDNDdFRTkxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODZDNDdFRTgxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTUxRUI3MDZEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTUxRUI3MDdEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz69wtu7AAAAe0lEQVR42mLce+zSOVFhYUMGNHDv4cOd/q6WHgxkAqbvP77H/P339zey4Nfv3z7ceXA/hoECwCQnLXPtw8eP05EFHz15WuRm7/CGIoNBhLCgUPnPX79egdgv37w+qKmqOp+BQsAEpX8wMTFm/fnz5/P/f//DGagAAAIMAKIuMR+q/rU9AAAAAElFTkSuQmCC");
}

select.form-control[multiple] {
  background: #fff;
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
}

/* BADGES */
.badge {
  font-weight: normal;
  background: #fff;
  color: #6a7a84;
}
.badge.badge-default {
  background: #acb7bf;
  color: #fff;
}
.badge.badge-primary {
  background: #0190fe;
  color: #fff;
}
.badge.badge-secondary {
  background: #6a7a84;
  color: #fff;
}
.badge.badge-success {
  background: #46be8a;
  color: #fff;
}
.badge.badge-info {
  background: #0887c9;
  color: #fff;
}
.badge.badge-danger {
  background: #fb434a;
  color: #fff;
}
.badge.badge-warning {
  background: #f39834;
  color: #fff;
}

.btn .badge {
  top: 1px;
}

/* CAROUSEL */
.carousel-indicators li {
  background: #eef0f4;
  opacity: 0.5;
}
.carousel-indicators li.active {
  opacity: 1;
  background: #eef0f4;
}

/* COLLAPSE */
.accordion .card {
  border-radius: 0;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.accordion .card:first-child {
  border-radius: 5px 5px 0 0;
}
.accordion .card:last-child {
  border-radius: 0 0 5px 5px;
  border-bottom-width: 1px;
}
.accordion .card .card-header {
  cursor: pointer;
}
.accordion .card .card-header .card-title {
  margin-bottom: 0;
}
.accordion .card .card-header.collapsed {
  border: none;
}
.accordion.accordion-margin-bottom .card {
  border-radius: 5px;
  margin-bottom: 1.15rem;
  border-bottom-width: 1px;
}
.accordion .accordion-indicator {
  padding-top: 1px;
  color: #b8beca;
}
.accordion .accordion-indicator .plus {
  display: none;
}
.accordion .accordion-indicator .minus {
  display: inline;
}
.accordion .collapsed .accordion-indicator .plus {
  display: inline;
}
.accordion .collapsed .accordion-indicator .minus {
  display: none;
}

/* MODAL */
.modal .modal-content {
  border: none;
}
.modal .modal-header .close {
  outline: none !important;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}
.modal .modal-header .close span {
  position: relative;
  top: 3px;
}
.modal.modal-size-small {
  padding-left: 10px;
  padding-right: 10px;
}
.modal.modal-size-small .modal-dialog {
  max-width: 300px;
  width: auto;
}
@media (max-width: 575px) {
  .modal.modal-size-small .modal-dialog {
    max-width: none;
  }
}
.modal.modal-size-large {
  padding-left: 10px;
  padding-right: 10px;
}
.modal.modal-size-large .modal-dialog {
  max-width: 980px;
  width: auto;
}

.modal-backdrop {
  background: #0e0b20;
}
.modal-backdrop.in {
  opacity: 0.3;
}

.modal-body {
  padding: 1.53rem 3.84rem;
  min-width: 69.23rem;
}
@media (max-width: 991px) {
  .modal-body {
    min-width: 0;
  }
}

/* ALERTS */
.alert {
  border: none;
  font-weight: normal;
  color: #fff;
}
.alert .close {
  color: #fff;
  opacity: 0.5;
  outline: none !important;
}
.alert .close:hover {
  opacity: 1;
}
.alert a,
.alert .alert-link {
  font-weight: normal;
  color: #fff;
  opacity: 0.7;
  transition: color 0s ease-in-out;
}
.alert a:hover,
.alert .alert-link:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-default {
  background: #acb7bf;
}
.alert.alert-primary {
  background: #0190fe;
}
.alert.alert-secondary {
  background: #6a7a84;
}
.alert.alert-success {
  background: #46be8a;
}
.alert.alert-info {
  background: #0887c9;
}
.alert.alert-warning {
  background: #f39834;
}
.alert.alert-danger {
  background: #fb434a;
}

/* PAGINATION */
.pagination :global(.ant-pagination-options) {
  display: none;
}
.pagination .page-link {
  border-color: #e4e9f0;
  color: #74708d;
  outline: none;
  margin-bottom: 0.76rem;
}
.pagination .page-link:hover, .pagination .page-link:focus {
  background: #acb7bf;
  color: #fff;
  border-color: #acb7bf;
}
.pagination .page-item.disabled .page-link {
  background: #f2f4f8;
}
.pagination .page-item.active .page-link {
  background: #0190fe;
  border-color: #0190fe;
}

.pager li > a {
  border-color: #b8beca;
  color: #393749;
  outline: none;
  border-radius: 3px;
}
.pager li > a:hover, .pager li > a:focus {
  background: #acb7bf;
  color: #fff;
  border-color: #acb7bf;
}

/* TABS */
.nav-tabs-horizontal .nav-tabs {
  border-bottom: 1px solid #e4e9f0;
}
.nav-tabs-horizontal .nav-tabs .nav-item {
  margin-bottom: -2px;
  cursor: pointer;
}
.nav-tabs-horizontal .nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
}
.nav-tabs-horizontal .nav-tabs .nav-item .nav-link.active, .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:focus {
  border-bottom-color: #0190fe !important;
}
.nav-tabs-horizontal .nav-tabs .nav-item .nav-link:hover {
  border-bottom-color: #b8beca;
}

.nav-tabs-vertical:after {
  clear: both;
  content: "";
  display: block;
}
.nav-tabs-vertical .nav-tabs {
  border-bottom: none;
  border-right: 1px solid #e4e9f0;
  float: left;
  margin-right: 30px;
  display: block;
}
.nav-tabs-vertical .nav-tabs .nav-item {
  margin: 0 -2px 1px 0;
  float: none;
  cursor: pointer;
}
.nav-tabs-vertical .nav-tabs .nav-item .nav-link {
  border: none;
  border-right: 3px solid transparent;
  padding-left: 0;
  border-radius: 0;
}
.nav-tabs-vertical .nav-tabs .nav-item .nav-link.active, .nav-tabs-vertical .nav-tabs .nav-item .nav-link:focus {
  border-right-color: #0190fe !important;
}
.nav-tabs-vertical .nav-tabs .nav-item .nav-link:hover {
  border-right-color: #b8beca;
}

/* TOOLTIPS & POPOVERS */
.popover {
  border-color: #d2d9e5;
}
.popover .popover-inner {
  border-width: 0.61rem;
}
.popover .popover-inner:after {
  border-width: 0.53rem;
}
.popover.left:before, .popover.bs-tether-element-attached-left:before {
  border-right-color: #d2d9e5;
}
.popover.right:before, .popover.bs-tether-element-attached-right:before {
  border-left-color: #d2d9e5;
}
.popover.top:before, .popover.bs-tether-element-attached-top:before {
  border-bottom-color: #d2d9e5;
}
.popover.bottom:before, .popover.bs-tether-element-attached-bottom:before {
  border-top-color: #d2d9e5;
}
.popover .popover-title {
  font-weight: 600;
  background: #f2f4f8;
  margin: -1px;
  padding: 0.61rem 1.15rem;
  border-radius: 3px 3px 0 0;
}

.tooltip .tooltip-inner {
  background: #222034;
  padding: 0.3rem 1.15rem;
  color: #fff;
  font-size: 1rem;
}
.tooltip.left .tooltip-inner:before, .tooltip.bs-tether-element-attached-left .tooltip-inner:before {
  border-right-color: #222034;
}
.tooltip.right .tooltip-inner:before, .tooltip.bs-tether-element-attached-right .tooltip-inner:before {
  border-left-color: #222034;
}
.tooltip.top .tooltip-inner:before, .tooltip.bs-tether-element-attached-top .tooltip-inner:before {
  border-bottom-color: #222034;
}
.tooltip.bottom .tooltip-inner:before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner:before {
  border-top-color: #222034;
}

.chartist-tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #001529;
  color: #fff;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.2s linear;
  border-radius: 2px;
}

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  border: 7px solid transparent;
  border-top-color: #001529;
}

.chartist-tooltip.tooltip-show {
  display: inline-block;
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1;
}

.ct-chart {
  position: relative;
}

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 4px 10px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transform: translateY(13px);
  transition: opacity 0.2s linear;
}

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 4px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}

.ct-bar {
  fill: none;
  stroke-width: 10px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #0190fe;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #0190fe;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #46be8a;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #46be8a;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #ff0;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #ff0;
}

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #d17905;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #d17905;
}

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #453d3f;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #453d3f;
}

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #59922b;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #59922b;
}

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3;
}

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
  fill: #0544d3;
}

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392;
}

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
  fill: #6b0392;
}

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f;
}

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
  fill: #f05b4f;
}

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #dda458;
}

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
  fill: #dda458;
}

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d;
}

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
  fill: #eacf7d;
}

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #86797d;
}

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
  fill: #86797d;
}

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326;
}

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
  fill: #b2c326;
}

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
  fill: #6188e2;
}

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
  fill: #a748ca;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
}

.ct-square:after {
  content: "";
  display: table;
  clear: both;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}

.ct-minor-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%;
}

.ct-major-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%;
}

.ct-minor-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
}

.ct-major-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
}

.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%;
}

.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%;
}

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
}

.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%;
}

.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
}

.ct-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
}

.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%;
}

.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
}

.ct-double-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/*  NPROGRESS */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  display: none;
  height: 3px;
  background: #0190fe;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  width: 10.76rem;
  padding: 0.61rem 1.15rem;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e4e9f0;
  right: auto;
  left: 50%;
  top: 1rem;
  margin-left: -5.62rem;
}
#nprogress .spinner:after {
  content: "Loading...";
  display: inline-block;
  position: absolute;
  top: 0.53rem;
  left: 3.69rem;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #0190fe;
  border-left-color: #0190fe;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  RCDRAWER */
.drawer-handle {
  background: #001529 !important;
  top: 104px;
}

.drawer.drawer-open .drawer-handle-icon {
  background: transparent !important;
}

.drawer-handle-icon {
  background: #fff !important;
}
.drawer-handle-icon:after, .drawer-handle-icon:before {
  background: #fff !important;
}

.drawer .drawer-content {
  overflow: visible;
  background: #001529;
  transition: background 0.3s;
}
.drawer.drawer-light .drawer-content {
  background: #fff;
}

.drawer-content-wrapper {
  box-shadow: none !important;
}

.rbc-addons-dnd-resizable {
  display: flex;
}

.rbc-event-content {
  width: 100%;
}

.fas {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dp_tag_modal_btn_container .button_xsmall.dp_button.button_xsmall.button_type_distinct.button_variant_regular.button_variant_regular_distinct {
  min-width: inherit !important;
}

.btn.is-isolated:nth-child(3) {
  display: none;
}

.w-10px {
  width: 10px;
}

.w-15px {
  width: 15px;
}

.w-20px {
  width: 20px;
}

@media (min-width: 1011px) {
  main {
    overflow: auto;
    display: block;
    width: auto;
    height: auto;
  }
  .ant-btn-group {
    display: flex;
  }
}
@media (max-width: 600px) {
  .ant-btn-group {
    text-align: center;
  }
}
@media (max-width: 1010px) {
  main {
    overflow: auto;
    display: block;
    width: auto;
    height: auto;
  }
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: 100%;
  }
}
.ant-modal {
  color: #000;
}

html,
body {
  overflow: hidden !important;
  padding: 0 0 0 0 !important;
  min-width: 300px;
}
html #root,
body #root {
  width: 100%;
  height: 100%;
}

.add-task-modal .ant-form-item-control-wrapper {
  width: 100%;
}
.add-task-modal .ant-form-item-control-wrapper .ant-form-item-children {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.add-task-modal .ant-form-item-control-wrapper .ant-form-explain {
  padding-left: 50px;
}

.dummyimage {
  width: 100%;
  max-width: 1440px;
}

.dp-scroll {
  color: rgba(0, 0, 0, 0);
  transition: color 1s ease;
}

.dp-scroll:hover {
  color: rgba(0, 0, 0, 0.1);
}

.dp-scroll::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.35);
}

.dp-scroll::-webkit-scrollbar,
.dp-scroll::-webkit-scrollbar-thumb {
  width: 10px;
  background-clip: padding-box;
  border: 0 solid transparent;
  box-shadow: inset 0 0 0 10px;
}

.dp-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.2);
}

.ant-popover {
  z-index: 1050;
}

.donecheck-40 .ant-checkbox {
  transform: scale(1);
  top: 5%;
}
.donecheck-40 .ant-checkbox .ant-checkbox-inner {
  border-radius: 100%;
  padding: 8px;
  width: 40px;
  height: 40px;
}
.donecheck-40 .ant-checkbox-checked .ant-checkbox-inner {
  background: green;
  border-color: green;
}
.donecheck-40 .ant-checkbox-checked .ant-checkbox-inner:after {
  transform-origin: center center;
  transform: rotate(45deg) scale(1.5) translate(20%, -70%);
}

.progress-antd .ant-progress-text {
  font-size: 10px;
  font-weight: 600;
}

.send-email {
  float: left;
  margin-left: 10px;
}

::selection {
  color: #fff;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.d-flex-row-revers {
  display: flex;
  flex-direction: row-reverse;
}

.d-flex {
  display: flex;
}

.ant-tabs-nav-scroll {
  user-select: none;
}

.ant-calendar-ok-btn {
  color: #fff !important;
}

div[role=tab],
button {
  cursor: pointer;
}

.scrollarea .scrollbar-container {
  z-index: inherit !important;
}

.deleted-list-item-box {
  text-align: center;
  border-radius: 4px;
  padding: 10px 10px;
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: whitesmoke;
  color: rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 10px;
  cursor: not-allowed;
}

.ant-checkbox .ant-checkbox-inner {
  transition: none;
}
.ant-checkbox .ant-checkbox-inner::after {
  transition: none;
}

.react-grid-item {
  transition: none;
  transition-property: none;
}

.ant-checkbox-checked::after {
  transition: none;
  border: none;
  animation: none;
}

.react-grid-placeholder {
  background: #eee !important;
}

.utils__scrollTable {
  white-space: normal;
}

.trash-undo {
  color: rgba(0, 128, 0, 0.3137254902);
}

.trash-undo:hover {
  color: green;
}

.remove-icon {
  color: red;
}

.trash-delete {
  color: rgba(255, 0, 0, 0.3137254902);
}

.trash-delete:hover {
  color: red;
}

.dp-table-deleted-row {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f2f4f8;
  border-color: #e4e9f0;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp-table-deleted-row .src-avatar {
  filter: grayscale(100%);
}

.dp-table-clickable-row {
  cursor: pointer;
}

.row-disable {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f2f4f8;
  border-color: #e4e9f0;
  text-shadow: none;
  box-shadow: none;
}

.ant-calendar-selected-day .ant-calendar-date {
  color: white !important;
}

.sideButtons .dp_button {
  margin-left: 0;
}

.sidebtn-row .sideButtons {
  visibility: hidden;
  background: white;
  padding: 5px;
  border-radius: 5px;
  text-align: right;
  margin-right: 6px;
}
.sidebtn-row .sideButtons .right-icon:first-child,
.sidebtn-row .sideButtons .left-icon:first-child {
  margin-left: 0;
}
.sidebtn-row .sideButtonsVis {
  padding: 5px;
  border-radius: 5px;
  text-align: right;
  padding-right: 6px;
}
.sidebtn-row .sideButtonsVis .right-icon:first-child,
.sidebtn-row .sideButtonsVis .left-icon:first-child {
  margin-left: 0;
}

.sidebtn-row:hover .sideButtons {
  visibility: visible;
}

.forceVisible {
  visibility: visible !important;
}

.forceTransparent {
  background: transparent !important;
}

.tiny-widget-icon {
  font-size: 20px;
  display: block;
}

.dp-table-clickable-row .ant-table-cell {
  position: static;
}

.ant-table-content .sideButtons {
  visibility: hidden;
  z-index: 1;
  background: white;
  padding: 5px;
  border-radius: 5px;
  text-align: right;
  margin-right: 6px;
  align-items: center;
}
.ant-table-content .sideButtons .right-icon:first-child,
.ant-table-content .sideButtons .left-icon:first-child {
  margin-left: 0;
}
.ant-table-content .ant-table-row:hover .sideButtons {
  visibility: visible;
}

@media (max-width: 820px) {
  .sideButtons {
    visibility: visible !important;
  }
  .dp-singletask-items .dp-singletask-icons {
    visibility: visible !important;
  }
}
.dp-singletask-userdropdown-wrapper {
  padding-left: 10px;
}

.btn-title-row .sideButtons .button_default {
  padding: 2px 21px !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row td .title {
  cursor: pointer;
  display: block;
  min-height: 15px;
  min-width: 10px;
}
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row .sideButtons {
  visibility: hidden;
  background: white;
  padding: 5px;
  border-radius: 5px;
  text-align: right;
  margin-right: 6px;
  align-items: center;
}
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row .sideButtons .right-icon:first-child,
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row .sideButtons .left-icon:first-child {
  margin-left: 0;
}
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .sideButtonsVis {
  padding: 5px;
  border-radius: 5px;
  text-align: right;
  padding-right: 6px;
}
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .sideButtonsVis .right-icon:first-child,
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .sideButtonsVis .left-icon:first-child {
  margin-left: 0;
}
.ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row:hover .sideButtons {
  visibility: visible;
}

.dp-sortable-table .ant-table-content .ant-table-body .ant-table-fixed .ant-table-thead {
  display: table-header-group;
}
.dp-sortable-table .ant-table-content .ant-table-body .ant-table-fixed .ant-table-tbody .ant-table-row {
  transition: none;
}

.search {
  margin-left: 10px;
  border: 1px solid #e4e9f0;
  border-radius: 5px;
  overflow: hidden;
  height: 32px;
  padding: 0px;
}
.search:hover {
  border-color: #b8beca;
}
.search input {
  border: 0px;
  padding: 5px 10px;
}
.search .ant-input-group-addon {
  background-color: #fff;
}
.search .ant-input-group-addon .ant-input-search-button {
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 0px;
  margin-right: 5px;
}
.search .ant-input-group-addon .ant-input-search-button .anticon-search {
  color: #393749;
}

.results {
  padding: 5px;
  background-color: #fff;
}
.results .ant-tabs-extra-content {
  display: flex;
}
.results .results-sub-tabs {
  background-color: #f2f4f8;
}
.results .ant-tabs-tab .anticon {
  margin-right: 0px;
}
.results .ant-tabs-bar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.results .ant-tabs-bar .ant-tabs-extra-content {
  color: #78749b;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
}
.results .ant-tabs-bar .ant-tabs-extra-content .ant-tabs-tab:hover {
  color: #0d0c0c;
}

.dp-list .ant-list-items .ant-list-item .ant-list-item-meta span {
  cursor: pointer;
}
.dp-list .ant-list-items .ant-list-item .sideButtons {
  visibility: hidden;
}
.dp-list .ant-list-items .ant-list-item:hover .sideButtons {
  visibility: visible;
}

.settings .ant-radio-group .ant-radio-button-wrapper {
  background: linear-gradient(to top, #f4f4f4 40%, rgb(255, 255, 255) 100%);
  color: #443f65;
  font-weight: 700;
}
.settings .ant-radio-group .ant-radio-button-wrapper-checked {
  background: radial-gradient(circle, rgb(229, 229, 229) 50%, rgb(201, 201, 201) 100%);
  box-shadow: none;
  border: none;
}

.dp-card .card {
  border: none;
}
.dp-card .card .card-header {
  padding: 8px 0;
}
.dp-card .card .card-header .utils__title strong {
  margin: 0;
}
.dp-card .card .card-header .ant-btn {
  background-color: white;
  color: #333;
  text-shadow: none;
  border-radius: 4px;
  margin-right: 8px;
  border-color: #777;
  font-size: 12px;
  padding: 2px 5px;
  height: auto;
}
.dp-card .card .card-body {
  padding: 0;
}
.dp-card .card .card-body .ant-table-thead {
  display: none;
}
.dp-card .card .card-body .ant-table-content .ant-table-body {
  overflow-y: hidden;
  overflow-x: auto !important;
}
.dp-card .card .card-body .ant-table-content .ant-table-body .ant-table-fixed .ant-table-row td {
  padding: 6px;
}
.dp-card .card .card-body .ant-table-content .ant-table-body .ant-table-fixed .ant-table-row td .sideButtons {
  text-align: right;
}

.antd-header-and-table .ant-table-thead {
  display: none;
}
.antd-header-and-table .ant-table-content .ant-table-body {
  overflow-y: hidden;
}
.antd-header-and-table .ant-table-content .ant-table-body .ant-table-fixed .ant-table-row td {
  padding: 6px;
}
.antd-header-and-table .ant-table-content .ant-table-body .ant-table-fixed .ant-table-row td .sideButtons {
  text-align: right;
}

.fc-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.fc-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

.fc-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.fc-right .fc-myCustomButton-button {
  font-size: 1em;
  margin: 0 !important;
  cursor: auto !important;
  border: none !important;
  outline: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  padding: 0 !important;
  box-shadow: none !important;
  background: none !important;
  -webkit-appearance: none !important;
  line-height: 1.2;
}

.src-avatar .ant-avatar {
  width: 28px;
  height: 28px;
}
.src-avatar .ant-avatar .ant-avatar-string {
  position: unset;
}

.bg-avatar {
  min-width: 100%;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-color: white;
}

.production-tabs .ant-tabs {
  overflow: unset;
}

.ant-modal-wrap.draggable {
  overflow: hidden;
  background: rgba(78, 78, 78, 0.55);
}
.ant-modal-wrap.draggable .ant-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-modal-wrap.draggable .ant-modal .ant-modal-content .ant-modal-body {
  flex: 1;
  overflow: auto;
}

.ant-modal-wrap.fixed {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-wrap.fixed .ant-modal {
  position: unset;
}

.ant-modal-wrap.modal-gray-bg .ant-modal .ant-modal-body {
  background-color: #f2f4f8;
}

.ant-modal-wrap.fullscreen {
  overflow: hidden;
}
.ant-modal-wrap.fullscreen .ant-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ant-modal-wrap.fullscreen .ant-modal .ant-modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
}
.ant-modal-wrap.fullscreen .ant-modal .ant-modal-content .ant-modal-header {
  height: 55px;
}
.ant-modal-wrap.fullscreen .ant-modal .ant-modal-content .ant-modal-body {
  flex: 1;
  overflow: auto;
}

.ant-modal-wrap.fullscreen-height .ant-modal {
  padding: 0;
  margin: 0;
  height: 100%;
}

.ant-tooltip {
  max-width: unset;
}
.ant-tooltip .ant-tooltip-inner {
  white-space: pre;
}

.ant-modal-wrap.non-mask {
  position: unset;
}
.ant-modal-wrap.non-mask .ant-modal {
  position: fixed;
  z-index: 1000;
}

.widget-modal .ant-modal {
  top: 80px !important;
  right: 15px !important;
  left: auto !important;
  bottom: auto !important;
}

.ant-modal-wrap.non-resizeable .ant-modal .ant-design-draggable-modal-resize-handle {
  display: none;
}

.ant-modal-wrap.non-resizeable-width .ant-modal {
  width: 800px !important;
}

.ant-modal-wrap.non-resizeable-height .ant-modal {
  height: 100% !important;
}
@media (min-width: 455px) {
  .ant-modal-wrap.non-resizeable-height .ant-modal {
    max-height: 800px !important;
  }
}

@media (max-width: 455px) {
  .ant-modal {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
}
.ant-modal-wrap.help-report {
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: flex-start;
}
@media (min-width: 455px) {
  .ant-modal-wrap.help-report .ant-modal {
    bottom: 90px !important;
    left: 20px !important;
    height: fit-content !important;
    top: auto !important;
  }
}
@media (min-height: 600px) {
  .ant-modal-wrap.help-report .ant-modal {
    bottom: 90px !important;
    left: 20px !important;
    height: fit-content !important;
    top: auto !important;
  }
}
@media (max-height: 600px) {
  .ant-modal-wrap.help-report .ant-modal {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    height: 100% !important;
  }
}
@media (max-width: 455px) {
  .ant-modal-wrap.help-report .ant-modal {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    height: 100% !important;
  }
}
.ant-modal-wrap.help-report .ant-modal .ant-modal-content .ant-modal-body {
  max-height: 720px;
}
@media (max-height: 780px) {
  .ant-modal-wrap.help-report .ant-modal .ant-modal-content .ant-modal-body {
    max-height: 367px !important;
  }
}

.ant-modal-wrap.help {
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: flex-start;
}
@media (min-width: 455px) {
  .ant-modal-wrap.help .ant-modal {
    top: 0 !important;
    right: 0;
    left: calc(100% - 35em) !important;
  }
}
@media (max-width: 455px) {
  .ant-modal-wrap.help .ant-modal {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 10px;
}
.ant-upload.ant-upload-select-picture-card .ant-upload {
  padding: 0;
}

.task-check {
  width: 40px;
}

.deleteIconStyle {
  font-size: 25px;
  margin-top: 0.4%;
  margin-left: 5px;
}

.fillStar {
  color: #ffd700;
}

.emptyStar {
  cursor: pointer;
}

.right-icon {
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
}

.left-icon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px 0 5px;
}

.error-message {
  color: red;
  font-size: 1.5em;
}

.error-message-account {
  text-align: right;
  margin-top: -20px;
  margin-bottom: 5px;
  padding-right: 7%;
}

.dp-card-setting-body {
  padding: 20px 20px 50px 20px;
}

.account {
  display: inline-block;
  background-color: #f5f3ee;
  color: #514c6a;
  padding: 2px 5px;
  font-size: 14px;
  /* margin-right: 20px; */
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
}

.sideButtons {
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-right: 6px;
  right: 10px;
}

.sideButtonsVis {
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
}

.btn-title-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.sideImage {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.editIcon {
  font-size: 25px;
  margin-left: 5px;
}

.user-form div div:first-child {
  text-align: right;
  padding-right: 10px;
}
.user-form div div:first-child label {
  padding-top: 7px;
}

.create-user-form div:nth-child(9) div:nth-child(2) .ant-form-item-control {
  text-align: left;
}
.create-user-form div:nth-child(9) div:nth-child(2) .ant-form-item-control .ant-form-item-children div {
  text-align: left;
  width: 100% !important;
}
.create-user-form div:nth-child(9) div:nth-child(2) .ant-form-item-control .ant-form-item-children div input {
  width: 107% !important;
}

.success-message {
  font-size: 1.5em;
  color: rgb(23, 230, 40);
}

.card-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.card-header-title-label {
  padding-left: 30px;
}

.utils__title {
  font-size: 1.23rem;
  line-height: 1.69rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.utils__title > div > strong,
.utils__title > span,
.utils__title > strong {
  display: inline-block;
  font-weight: bolder;
  font-size: 1.692rem;
}

.service-button {
  margin: 10px;
}

.service-btnicon {
  margin-top: -5px !important;
}

@media (max-width: 600px) {
  .task-widget .right-icon {
    margin-left: 2px;
  }
  .task-widget .right-icon svg {
    width: 0.8em;
  }
  .task-widget .left-icon {
    font-size: 1em;
    margin: 0;
  }
  .task-widget .number-input {
    width: 32px;
    height: 33px;
    font-size: 12px;
    padding: 0;
    text-align: center;
    margin-right: 2px;
  }
  .task-widget .main-input {
    margin: 0 2px;
    padding: 0;
  }
  .task-widget .main-input .ant-input {
    margin: 0 2px;
  }
  .task-widget .main-input .checkbox {
    margin: 0 2px;
  }
  .task-widget .main-input .checkbox .ant-checkbox-inner:after {
    width: 4px;
  }
}
.fc-time-grid-container {
  height: 520px !important;
  max-height: calc(100vh - 230px);
}

.fc-content {
  color: #fff;
  font-size: 15px;
}

.ant-menu.ant-menu-light .ant-menu-item-selected.custommenuitem {
  background-color: #f5f3ee;
  font-weight: bold;
  color: #78749b !important;
}
.ant-menu.ant-menu-light .menu-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu.ant-menu-light .ant-menu-item-selected.menu-pagination {
  background-color: #fff;
}

.responsible-user-avatar-small {
  width: 25px;
  height: 25px;
  background-color: gray;
  margin-right: 4px;
}
.responsible-user-avatar-small svg {
  margin-left: 1px;
  margin-bottom: 4px;
}

.clickable {
  cursor: pointer;
}

.responsible-user-avatar-large {
  width: 25px;
  height: 25px;
  background-color: gray;
}
.responsible-user-avatar-large svg {
  font-size: 14px;
  margin-bottom: 5px;
}

.agency-account-import-button {
  margin-right: 10px;
}

.dp-no-text-selection {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body .ant-layout-sider {
  z-index: 1 !important;
}

body .ant-layout-content {
  height: 93vh;
}

.ant-form-item-label {
  text-transform: capitalize;
}

.dp-group-list-header {
  font-size: 15px;
}

.ant-modal-title {
  padding-right: 1.5em;
}

.dp-dropdown-root-search {
  display: flex !important;
}

.fb-widgets-dropdown-error {
  padding: 1px;
  border-radius: 3px;
  transition: 0.3s linear;
  background-color: red;
}

.dp-widget-card-title-warning-icon {
  color: red;
  font-size: 24px;
  margin-right: 10px;
}

.ant-calendar-in-range-cell > .ant-calendar-date {
  color: white;
}

.ant-picker-cell-in-range.ant-picker-cell-in-view > .ant-picker-cell-inner {
  color: white;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start > .ant-picker-cell-inner {
  margin-left: 9px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end > .ant-picker-cell-inner {
  margin-right: 10px;
}

.ant-calendar-week-number .ant-calendar-tbody tr:hover td.ant-calendar-week-number-cell {
  color: rgb(190, 190, 190);
}
.ant-calendar-week-number .ant-calendar-tbody tr:hover td.ant-calendar-cell div.ant-calendar-date {
  color: rgb(190, 190, 190);
}

.project_assignee_suppler_selector .ant-select-selection-overflow {
  max-height: 100px;
  overflow-y: auto;
}

.contactEditor-rightSide-wrapper .dp-dropdown-dropdown {
  width: 170px;
}

.budget-widget-card-container {
  border: none !important;
}
.budget-widget-card-container .ant-card-head {
  border: none;
}
.budget-widget-card-container .ant-card-head-wrapper {
  border: none;
}
.budget-widget-card-container .ant-card-body {
  border: none;
}

.dp-header-body-flex {
  display: flex;
  align-items: center;
}
.dp-header-body-flex .settings-icon {
  font-size: 1.5rem;
  margin-left: 15px;
}

.budgetManualCostModal .ant-input-number {
  width: 100%;
}
.budgetManualCostModal .budgetManualCostDatePicker {
  width: 100%;
}
.budgetManualCostModal .budgetManualCostDatePicker .ant-picker {
  width: 100%;
}

.meeting-editor-container #paste-div {
  height: 100%;
}
.meeting-editor-container #paste-div section {
  height: 100%;
}
.meeting-editor-container #paste-div section > div:nth-child(1) {
  height: 100%;
}
.meeting-editor-container #paste-div .dp-editmeeting-right {
  padding: 0px 10px;
}
.meeting-editor-container #paste-div .dp-editmeeting-right .ant-select-selection {
  max-height: 200px;
  overflow-y: auto;
  /* Handle on hover */
}
.meeting-editor-container #paste-div .dp-editmeeting-right .ant-select-selection::-webkit-scrollbar-thumb {
  background: darkgrey;
}
.meeting-editor-container #paste-div .dp-editmeeting-right .ant-select-selection::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.meeting-editor-container #paste-div .dp-editmeeting-right .ant-select-selection::-webkit-scrollbar {
  width: 7px;
}
.meeting-editor-container #paste-div .dp-editmeeting-right .ant-select-selection::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dp-topbar-accountdropdown-overlay td {
  width: 250px !important;
}
.dp-topbar-accountdropdown-overlay ul {
  width: 250px !important;
}

.dpui-autocomplete-root ul {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.dpui-autocomplete-root ul li {
  height: 2.5em !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e6f7ff !important;
}

.userAccountDropdown {
  padding-top: 0;
  width: 100%;
}
.userAccountDropdown .dp-dropdown-dropdown {
  width: 100% !important;
  max-width: 100% !important;
  margin-right: 0px !important;
}
.userAccountDropdown .dp-dropdown-dropdown .dp-dropdown-account {
  width: 100% !important;
  max-width: 100% !important;
}
.userAccountDropdown input {
  width: 100% !important;
  height: 32px;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  background: rgb(229.5, 229.5, 229.5);
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgb(229.5, 229.5, 229.5);
  border-color: rgb(173.4, 173.4, 173.4);
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: rgb(211.65, 211.65, 211.65);
  border-color: rgb(140.25, 140.25, 140.25);
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: rgb(229.5, 229.5, 229.5);
  border-color: rgb(173.4, 173.4, 173.4);
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: rgb(229.5, 229.5, 229.5);
  border-color: rgb(173.4, 173.4, 173.4);
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: rgb(37.7432432432, 89.3513513514, 133.2567567568);
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #3174ad;
}
.rbc-show-more:hover, .rbc-show-more:focus {
  color: rgb(37.7432432432, 89.3513513514, 133.2567567568);
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid rgb(37.7432432432, 89.3513513514, 133.2567567568);
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid rgb(246.5, 246.5, 246.5);
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

.rbc-addons-dnd .rbc-addons-dnd-row-body {
  position: relative;
}
.rbc-addons-dnd .rbc-addons-dnd-drag-row {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(0, 0, 0, 0.3);
}
.rbc-addons-dnd .rbc-event, .rbc-addons-dnd .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-addons-dnd .rbc-background-event {
  transition: opacity 150ms;
}
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-addons-dnd .rbc-day-slot .rbc-background-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-day-slot .rbc-addons-dnd .rbc-background-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon, .rbc-addons-dnd .rbc-day-slot .rbc-background-event:hover .rbc-addons-dnd-resize-ew-icon, .rbc-day-slot .rbc-addons-dnd .rbc-background-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}
.rbc-addons-dnd .rbc-addons-dnd-dragged-event {
  opacity: 0;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview), .rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-day-slot .rbc-background-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview), .rbc-day-slot .rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-background-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
  opacity: 0.5;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 100%;
  text-align: center;
  position: absolute;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
  cursor: ns-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  top: 4px;
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  left: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  right: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
  display: none;
  border-left: 3px double;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  cursor: ew-resize;
}