.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f2f4f8;
  background-size: cover;
  transition: background-image 0.4s linear;
}
@media (max-width: 767px) {
  .layout :global(.login-heading) {
    display: none;
  }
}
.layout.light .content :global(.login-heading) {
  color: #fff;
}
.layout.light .navigationItems li a {
  color: #fff;
}
.layout.light .navigationItems li a:hover, .layout.light .navigationItems li a:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.layout.light .navigationItems li a.navigationActive {
  border-bottom: 1px solid #fff;
}
.layout.light .footer {
  color: #fff;
}
.layout.light .footer li a {
  color: #fff;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.84rem;
  align-self: flex-start;
  width: 100%;
  padding: 3.07rem;
}
@media (max-width: 991px) {
  .header {
    padding: 3.07rem 1.53rem 3.07rem;
  }
}
@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
    flex-wrap: nowrap;
    flex-shrink: 0;
    margin-bottom: 0;
  }
}

.logo {
  max-height: 3.07rem;
  flex-shrink: 0;
}
.logo img {
  max-height: 3.07rem;
}

.controls {
  margin-right: auto;
  margin-left: 1.53rem;
}
@media screen and (max-width: 991px) {
  .controls {
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-top: 0.76rem;
  }
}
@media screen and (max-width: 767px) {
  .controls {
    display: none;
  }
}

.navigation {
  padding-top: 0.76rem;
  padding-bottom: 0.76rem;
}
@media screen and (max-width: 767px) {
  .navigation {
    display: none;
  }
}

.navigationItems {
  list-style: none;
  font-size: 1.23rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .navigationItems {
    margin-top: 0.76rem;
  }
}
.navigationItems li {
  text-transform: uppercase;
  margin-left: 0.76rem;
  margin-right: 0.76rem;
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
  flex-grow: 1;
  text-align: center;
}
.navigationItems li:last-child {
  margin-right: 0;
}
.navigationItems li a {
  color: #74708d;
  font-weight: 700;
}
.navigationItems li a:hover, .navigationItems li a:focus {
  border-bottom: 1px solid rgba(116, 112, 141, 0.4);
}
.navigationItems li a.navigationActive {
  border-bottom: 1px solid #74708d;
}

.content {
  align-self: middle;
}

.footer {
  align-self: flex-end;
  width: 100%;
  color: #74708d;
  padding: 3.07rem;
}
@media (max-width: 991px) {
  .footer {
    padding: 3.07rem 1.53rem 3.07rem;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 3.07rem 1.53rem 1.53rem;
  }
}
.footer ul {
  margin-bottom: 0;
}
.footer ul li {
  margin-right: 1.53rem;
  text-transform: uppercase;
}
.footer ul li:last-child {
  margin-right: 0;
}
.footer ul li a {
  color: #74708d;
  opacity: 0.7;
  line-height: 1.4;
  display: inline-block;
  margin-right: 0.76rem;
}
.footer ul li a:hover {
  opacity: 1;
}